// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BtSWmsflLAZNpgAghb16 {\n  width: 100%;\n}\n.o0AQ77mIoJRTW4gsrzju {\n  min-width: 170px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Filters/styles.less"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AAEA;EACE,gBAAA;AAAF","sourcesContent":[".container {\n  width: 100%;\n}\n\n.minWidth {\n  min-width: 170px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "BtSWmsflLAZNpgAghb16",
	"minWidth": "o0AQ77mIoJRTW4gsrzju"
};
export default ___CSS_LOADER_EXPORT___;
