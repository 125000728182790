import { notification } from "antd";
import { errorMessage } from "./events";

export const message = "Server Error";

errorMessage.watch((error) =>
  notification.error({
    message,
    description: error.message,
    top: 70,
  })
);
