// THIS FILE IS GENERATED, DO NOT EDIT!

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export const enum SortDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export type InventoryOverviewConstants = {
  __typename?: "InventoryOverviewConstants";
  retailStatuses: Array<RetailStatus>;
  countries: Array<Scalars["String"]>;
  auto1Statuses: Array<Auto1Status>;
};

export type InventoryOverviewInput = {
  filter?: InputMaybe<Filter>;
  sort?: InputMaybe<Sort>;
  pager?: InputMaybe<Pager>;
};

export type Filter = {
  searchText?: InputMaybe<Scalars["String"]>;
  retailStatus?: InputMaybe<Scalars["String"]>;
  retailCountry?: InputMaybe<Scalars["String"]>;
  dateFrom?: InputMaybe<Scalars["String"]>;
  dateTo?: InputMaybe<Scalars["String"]>;
};

export type Sort = {
  value: Scalars["String"];
  direction: SortDirection;
};

export type Pager = {
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
};

export type InventoryOverviewPage = {
  __typename?: "InventoryOverviewPage";
  items: Array<InventoryOverviewItem>;
  total: Scalars["Int"];
  hasQueryRetailAdPermission: Scalars["Boolean"];
};

export type InventoryOverviewItem = {
  __typename?: "InventoryOverviewItem";
  adId: Scalars["String"];
  retailCountry?: Maybe<Scalars["String"]>;
  vehicle?: Maybe<Scalars["String"]>;
  retailStatus?: Maybe<Scalars["String"]>;
  publishedOnline?: Maybe<Scalars["Boolean"]>;
  daysInInventory?: Maybe<Scalars["Int"]>;
  retailPrice?: Maybe<Price>;
  stockNumber: Scalars["String"];
  vin?: Maybe<Scalars["String"]>;
  importedAt?: Maybe<Scalars["String"]>;
  auto1Status?: Maybe<Scalars["String"]>;
  auto1BuyPrice?: Maybe<Price>;
  orderConfirmed?: Maybe<Scalars["Boolean"]>;
  totalOrders?: Maybe<Scalars["Int"]>;
};

export type Price = {
  __typename?: "Price";
  amountMinorUnits?: Maybe<Scalars["Int"]>;
  currencyCode?: Maybe<Scalars["String"]>;
};

export type Auto1Status = {
  __typename?: "Auto1Status";
  id: Scalars["String"];
  label: Scalars["String"];
};

export type RetailStatus = {
  __typename?: "RetailStatus";
  id: Scalars["String"];
  label: Scalars["String"];
};

export type Query = {
  __typename?: "Query";
  inventoryOverviewPage: InventoryOverviewPage;
  inventoryOverviewConstants: InventoryOverviewConstants;
};

export type QueryInventoryOverviewPageArgs = {
  input?: InputMaybe<InventoryOverviewInput>;
};

export type InventoryOverviewConstantsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type InventoryOverviewConstantsQuery = {
  __typename?: "Query";
  inventoryOverviewConstants: {
    __typename?: "InventoryOverviewConstants";
    countries: Array<string>;
    retailStatuses: Array<{
      __typename?: "RetailStatus";
      id: string;
      label: string;
    }>;
    auto1Statuses: Array<{
      __typename?: "Auto1Status";
      id: string;
      label: string;
    }>;
  };
};

export type InventoryOverviewQueryVariables = Exact<{
  input?: InputMaybe<InventoryOverviewInput>;
}>;

export type InventoryOverviewQuery = {
  __typename?: "Query";
  inventoryOverviewPage: {
    __typename?: "InventoryOverviewPage";
    total: number;
    hasQueryRetailAdPermission: boolean;
    items: Array<{
      __typename?: "InventoryOverviewItem";
      adId: string;
      retailCountry?: string | null | undefined;
      vehicle?: string | null | undefined;
      retailStatus?: string | null | undefined;
      publishedOnline?: boolean | null | undefined;
      daysInInventory?: number | null | undefined;
      stockNumber: string;
      vin?: string | null | undefined;
      importedAt?: string | null | undefined;
      auto1Status?: string | null | undefined;
      orderConfirmed?: boolean | null | undefined;
      totalOrders?: number | null | undefined;
      retailPrice?:
        | {
            __typename?: "Price";
            amountMinorUnits?: number | null | undefined;
            currencyCode?: string | null | undefined;
          }
        | null
        | undefined;
      auto1BuyPrice?:
        | {
            __typename?: "Price";
            amountMinorUnits?: number | null | undefined;
            currencyCode?: string | null | undefined;
          }
        | null
        | undefined;
    }>;
  };
};

export declare const inventoryOverviewConstants: import("graphql").DocumentNode;
export declare const inventoryOverview: import("graphql").DocumentNode;

export const InventoryOverviewConstantsDocument = `
    query inventoryOverviewConstants {
  inventoryOverviewConstants {
    retailStatuses {
      id
      label
    }
    countries
    auto1Statuses {
      id
      label
    }
  }
}
    `;
export const InventoryOverviewDocument = `
    query inventoryOverview($input: InventoryOverviewInput) {
  inventoryOverviewPage(input: $input) {
    items {
      adId
      retailCountry
      vehicle
      retailStatus
      publishedOnline
      daysInInventory
      retailPrice {
        amountMinorUnits
        currencyCode
      }
      stockNumber
      vin
      importedAt
      auto1Status
      auto1BuyPrice {
        amountMinorUnits
        currencyCode
      }
      orderConfirmed
      totalOrders
    }
    total
    hasQueryRetailAdPermission
  }
}
    `;
export type Requester<C = {}> = <R, V>(
  doc: string,
  vars?: V,
  options?: C
) => Promise<R>;
export function getSdk<C>(requester: Requester<C>) {
  return {
    inventoryOverviewConstants(
      variables?: InventoryOverviewConstantsQueryVariables,
      options?: C
    ): Promise<InventoryOverviewConstantsQuery> {
      return requester<
        InventoryOverviewConstantsQuery,
        InventoryOverviewConstantsQueryVariables
      >(InventoryOverviewConstantsDocument, variables, options);
    },
    inventoryOverview(
      variables?: InventoryOverviewQueryVariables,
      options?: C
    ): Promise<InventoryOverviewQuery> {
      return requester<InventoryOverviewQuery, InventoryOverviewQueryVariables>(
        InventoryOverviewDocument,
        variables,
        options
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
