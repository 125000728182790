import dayjs, { Dayjs } from "dayjs";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/lib/date-picker/generatePicker";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";

dayjs.extend(weekday);
dayjs.extend(localeData);

// TODO: This is a hack to make the DatePicker work with extended dayjs.
const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig as any);

export default DatePicker;
