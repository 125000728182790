import { LOCALES } from "./locales";

export const DEFAULT_CONVERSION_MAJOR = 100;

enum CURRENCIES {
  EUR = "EUR",
  PLN = "PLN",
  SEK = "SEK",
}

export const CURRENCY_INFO_BY_CODE = {
  [CURRENCIES.EUR]: {
    conversionMajor: DEFAULT_CONVERSION_MAJOR,
    formatLocale: LOCALES.DE,
    currencySymbol: "€",
  },
  [CURRENCIES.PLN]: {
    conversionMajor: DEFAULT_CONVERSION_MAJOR,
    formatLocale: LOCALES.PL,
    currencySymbol: "zł",
  },
  [CURRENCIES.SEK]: {
    conversionMajor: DEFAULT_CONVERSION_MAJOR,
    formatLocale: LOCALES.SV_SE,
    currencySymbol: "kr",
  },
};
