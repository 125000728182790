import { attach, combine, createEvent, forward, merge, sample } from "effector";
import {
  itemsReset,
  setFilters,
  setPage,
  setPageSize,
  setAllFilters,
} from "./events";
import { FilterParams } from "./types";
import gate from "./gate";
import $filterParamsStore from "./filterParams.store";
import $itemsStore from "./items.store";
import { applyFilterParamsFX, getInventoryOverviewsFX } from "./effects";
import $isLoadingStore from "./isLoading.store";
import { $queryInput } from "./projections";
import { DEFAULT_PAGE } from "./constants";
import filterSamePayload from "../../utils/samePayload";
import $authTokenStore from "@models/auth/authToken.store";

// init
export const init = createEvent<AuthToken>();

// Apply filters on route change
const onPopState = gate.state.map((payload): FilterParams => {
  return {
    ...$filterParamsStore.defaultState,
    ...payload,
  };
});

forward({
  from: onPopState,
  to: setAllFilters,
});

// FilterParams
$filterParamsStore
  .on(setFilters, (state, payload) => ({
    ...state,
    ...payload,
    page: DEFAULT_PAGE,
  }))
  .on(setAllFilters, (_, payload) => payload)
  .on(setPage, (state, page) => ({
    ...state,
    page,
  }))
  .on(setPageSize, (state, pageSize) => ({
    ...state,
    pageSize,
  }));

// Items
$itemsStore
  .on(getInventoryOverviewsFX.doneData, (_, payload) => ({
    total: payload.total,
    items: payload.items,
    canQueryRetailAd: payload.hasQueryRetailAdPermission,
  }))
  .on(itemsReset, () => $itemsStore.defaultState);

// IsLoading
$isLoadingStore
  .on(getInventoryOverviewsFX, () => true)
  .on(getInventoryOverviewsFX.finally, () => false);

// Data loading
sample({
  source: combine({
    inputParams: $queryInput,
    authToken: $authTokenStore,
  }),
  clock: merge([setAllFilters, filterSamePayload(setFilters), init]),
  filter: ({ authToken }) => !!authToken,
  target: getInventoryOverviewsFX,
});

// Apply FilterParams
const applyFilterParams = attach({
  source: $filterParamsStore,
  effect: applyFilterParamsFX,
});
forward({
  from: merge([setPage, setPageSize, setFilters]),
  to: applyFilterParams,
});

// Catch errors
export const errors = merge([
  getInventoryOverviewsFX.fail.map(({ error }): Error => error),
]);
