import { Button, Table, Tag } from "antd";
import { CheckOutlined, MinusOutlined, EditOutlined } from "@ant-design/icons";
import { isNil } from "ramda";
import formatDate from "@utils/formatDate";
import formatPrice from "@utils/formatPrice";
import { DataQaSelectorEnum } from "@enums/dataQaSelector.enum";
import { InventoryOverviewItem } from "@gql/retail-inventory-overview-types";
import { RETAIL_AD_LINK } from "@constants/retailAdLink";
import { navigateToUrl } from "@utils/navigateToUrl";
import cn from "./styles.less";

const { Column } = Table;

interface Status {
  id: string;
  label: string;
}

export const renderText = (key: string, title: string) => (
  <span data-qa-selector={`${key}-header`}>{title}</span>
);

export const renderLink = (stockNumber: string, adId: string) => (
  <a href={`${RETAIL_AD_LINK}${adId}`} onClick={navigateToUrl}>
    {stockNumber}
  </a>
);

export const renderPrice = (price: Price) =>
  !isNil(price?.amountMinorUnits) ? (
    <span>{formatPrice(price)}</span>
  ) : (
    <MinusOutlined />
  );

export const renderDate = (value: string, dataQaSelector?: string) => (
  <span data-qa-selector={dataQaSelector}>
    {value ? formatDate(value) : <MinusOutlined />}
  </span>
);

export const renderBoolean = (value: boolean) =>
  value ? <CheckOutlined className={cn.checkOutlined} /> : <MinusOutlined />;

export const renderStatus = (
  status: string,
  statuses: Status[],
  dataQaSelector?: string
) => (
  <span data-qa-selector={dataQaSelector}>
    {status ? (
      <Tag color="#222">
        {statuses.find(({ id }) => id === status)?.label ?? status}
      </Tag>
    ) : (
      <MinusOutlined />
    )}
  </span>
);

export const renderActionButton = (adId: string) => (
  <Button
    icon={<EditOutlined />}
    type="primary"
    className={cn.editButton}
    href={`${RETAIL_AD_LINK}${adId}`}
    onClick={navigateToUrl}
  />
);

export const renderContentTextWithDataQaSelector = (
  value: string,
  dataQaSelector: string
) => <span data-qa-selector={dataQaSelector}>{value}</span>;

export const renderStockNumber = (
  stockNumber: string,
  id: string,
  asLink: boolean
) => (
  <span data-qa-selector={DataQaSelectorEnum.cellStockNumber}>
    {asLink ? renderLink(stockNumber, id) : stockNumber}
  </span>
);

interface Props {
  data: InventoryOverviewItem[];
  loading?: boolean;
  canQueryRetailAd?: boolean;
  auto1Statuses?: Auto1Status[];
  retailStatuses?: RetailStatus[];
}

function DataTable({
  data,
  loading = false,
  canQueryRetailAd = false,
  auto1Statuses = [],
  retailStatuses = [],
}: Props) {
  return (
    <Table
      dataSource={data}
      rowKey="stockNumber"
      pagination={false}
      loading={loading}
      scroll={{
        x: true,
      }}
      size="small"
    >
      <Column
        dataIndex="stockNumber"
        key="stockNumber"
        title={() => renderText("stockNumber", "Stock No.")}
        fixed="left"
        render={(stockNumber: string, item: InventoryOverviewItem) =>
          renderStockNumber(stockNumber, item.adId, canQueryRetailAd)
        }
      />
      <Column
        dataIndex="vin"
        key="vin"
        title={() => renderText("vin", "Vin")}
        render={(value: string) =>
          renderContentTextWithDataQaSelector(value, DataQaSelectorEnum.cellVin)
        }
      />
      <Column
        dataIndex="retailCountry"
        key="retailCountry"
        title={() => renderText("country", "Country")}
        render={(value: string) =>
          renderContentTextWithDataQaSelector(
            value,
            DataQaSelectorEnum.cellCountryCode
          )
        }
      />
      <Column
        dataIndex="vehicle"
        key="vehicle"
        title={() => renderText("vehicle", "Vehicle")}
      />
      <Column
        dataIndex="importedAt"
        key="importedAt"
        title={() => renderText("imported", "Imported")}
        render={(value: string) =>
          renderDate(value, DataQaSelectorEnum.cellFirstImportOn)
        }
      />
      <Column
        dataIndex="retailStatus"
        key="retailStatus"
        title={() => renderText("retailStatus", "Retail Status")}
        render={(value: string) =>
          renderStatus(
            value,
            retailStatuses,
            DataQaSelectorEnum.cellRetailAdState
          )
        }
      />
      <Column
        dataIndex="auto1Status"
        key="auto1Status"
        title={() => renderText("auto1Status", "Auto1 Status")}
        render={(value: string) => renderStatus(value, auto1Statuses)}
      />
      <Column
        dataIndex="publishedOnline"
        key="publishedOnline"
        title={() => renderText("publishedOnline", "Published Online")}
        render={renderBoolean}
      />
      <Column
        dataIndex="totalOrders"
        key="totalOrders"
        title={() => renderText("totalOrders", "Total Orders")}
        render={(value: string) =>
          renderContentTextWithDataQaSelector(
            value,
            DataQaSelectorEnum.cellTotalOrders
          )
        }
      />
      <Column
        dataIndex="orderConfirmed"
        key="orderConfirmed"
        title={() => renderText("orderConfirmed", "Order Confirmed")}
        render={renderBoolean}
      />
      <Column
        dataIndex="daysInInventory"
        key="daysInInventory"
        title={() => renderText("daysInInventory", "Days in Inventory")}
        render={(value?: number) => value ?? 0}
      />
      <Column
        dataIndex="auto1BuyPrice"
        key="auto1BuyPrice"
        title={() => renderText("auto1BuyPrice", "Auto1 Buy Price")}
        render={renderPrice}
      />
      <Column
        dataIndex="retailPrice"
        key="retailPrice"
        title={() => renderText("retailPrice", "Retail Price")}
        render={renderPrice}
      />
      {canQueryRetailAd && (
        <Column dataIndex="adId" key="actions" render={renderActionButton} />
      )}
    </Table>
  );
}

export default DataTable;
