import dayjs, { Dayjs } from "dayjs";
import DatePicker from "@components/DatePicker";
import { RangePickerProps } from "antd/lib/date-picker/generatePicker";
import { RangeValue } from "rc-picker/lib/interface";

const { RangePicker } = DatePicker;

interface Props {
  value?: [Date | null, Date | null];
  onChange?: (value: [Date | null, Date | null]) => void;
  controllerProps: RangePickerProps<Dayjs> & {
    "data-qa-selector"?: string;
  };
}

const DateRangePickerControlled = ({
  value,
  onChange,
  controllerProps,
}: Props) => {
  const dateRange: RangeValue<Dayjs> = [
    value && value[0] ? dayjs(value[0]) : null,
    value && value[1] ? dayjs(value[1]) : null,
  ];
  const handleChange = (dateRange: RangeValue<Dayjs>) => {
    onChange?.([
      dateRange?.[0]?.toDate() ?? null,
      dateRange?.[1]?.toDate() ?? null,
    ]);
  };
  return (
    <RangePicker
      {...controllerProps}
      value={dateRange}
      onChange={handleChange}
    />
  );
};

export default DateRangePickerControlled;
