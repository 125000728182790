// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rC4_5GE1LvDp5u0B9DVP {\n  color: #3fb618;\n}\na.G3vqmRTq7QbB8tXMvuJ0 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./src/components/DataTable/styles.less"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AAAF","sourcesContent":[".checkOutlined {\n  color: #3fb618;\n}\n\na.editButton {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkOutlined": "rC4_5GE1LvDp5u0B9DVP",
	"editButton": "G3vqmRTq7QbB8tXMvuJ0"
};
export default ___CSS_LOADER_EXPORT___;
