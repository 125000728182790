import $auto1StatusesStore from "./auto1Statuses.store";
import $countriesStore from "./countries.store";
import $retailStatuesStore from "./retailStatuses.store";
import { getDictionariesFX } from "./effects";
import $authTokenStore from "../auth/authToken.store";
import { attach } from "effector";

// Store data
$auto1StatusesStore.on(
  getDictionariesFX.doneData,
  (_, payload) => payload.auto1Statuses
);
$countriesStore.on(
  getDictionariesFX.doneData,
  (_, payload) => payload.countries
);
$retailStatuesStore.on(
  getDictionariesFX.doneData,
  (_, payload) => payload.retailStatuses
);

// Catch errors
export const errors = getDictionariesFX.fail.map(({ error }) => error);

// Init
export const init = attach({
  source: $authTokenStore,
  effect: getDictionariesFX,
});
