import { createEvent } from "effector";
import { FilterParams } from "./types";

export const setPage = createEvent<number>();
export const setPageSize = createEvent<number>();
export const setFilters =
  createEvent<
    Pick<
      FilterParams,
      "dateFrom" | "dateTo" | "retailCountry" | "retailStatus" | "searchText"
    >
  >();
export const setAllFilters = createEvent<FilterParams>();
export const itemsReset = createEvent();
