import {
  pipe,
  omit,
  filter,
  complement,
  isNil,
  either,
  isEmpty,
  Dictionary,
} from "ramda";
import { combine, Store } from "effector";
import $itemsStore from "./items.store";
import $filterParamsStore from "./filterParams.store";
import $isLoadingStore from "./isLoading.store";
import { FilterParams } from "@models/inventoryOverview/types";

export const $inventoryOverviewData = combine({
  items: $itemsStore.map(({ items }) => items),
  totalItems: $itemsStore.map(({ total }) => total),
  canQueryRetailAd: $itemsStore.map(({ canQueryRetailAd }) => canQueryRetailAd),
  page: $filterParamsStore.map(({ page }) => page),
  pageSize: $filterParamsStore.map(({ pageSize }) => pageSize),
  filters: $filterParamsStore.map((state) => ({
    dateRange: [
      state.dateFrom ? new Date(state.dateFrom) : null,
      state.dateTo ? new Date(state.dateTo) : null,
    ] as const,
    retailStatus: state.retailStatus ?? undefined,
    retailCountry: state.retailCountry ?? undefined,
    searchText: state.searchText ?? undefined,
  })),
  isLoading: $isLoadingStore,
});

export type QueryInput = {
  pager: { page: number; pageSize: number };
  filter: Dictionary<string | null>;
};

export const $queryInput: Store<QueryInput> = $filterParamsStore.map(
  (state: FilterParams) => ({
    pager: {
      page: state.page,
      pageSize: state.pageSize,
    },
    filter: pipe(
      omit(["page", "pageSize"]),
      filter(complement(either(isNil, isEmpty)))
    )(state),
  })
);
