import { useGate } from "effector-react";
import ListPage from "@pages/ListPage";
import Gate from "@models/auth/gate";
import getCookieByName from "@utils/getCookieByName";
import "@models/init";

interface Props {
  accessToken: AuthToken;
}

let localAccessToken: AuthToken;

if (__WEBPACK_DEV_SERVER__) {
  localAccessToken = getCookieByName("t") ?? "";
}
export default function Root({ accessToken = localAccessToken }: Props) {
  useGate(Gate, accessToken);
  return <ListPage />;
}
