// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YlOibHUPsAsiipdzJWak {\n  padding: 20px;\n  max-width: 100%;\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ListPage/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,WAAA;AACF","sourcesContent":[".container {\n  padding: 20px;\n  max-width: 100%;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "YlOibHUPsAsiipdzJWak"
};
export default ___CSS_LOADER_EXPORT___;
