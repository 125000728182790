import callApi, { METHODS, Options } from "./callApi";

const callGql =
  (url: string) =>
  async <T extends unknown>(
    query: string,
    variables?: any,
    options?: Options & { mockedResult?: any }
  ): Promise<T> => {
    if (options?.mockedResult) {
      return Promise.resolve(options?.mockedResult);
    }

    const response = await callApi<{ data: T }>(
      METHODS.POST,
      url,
      {
        query,
        variables,
      },
      options
    );
    return response.data;
  };

export default callGql;
