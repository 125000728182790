import { createStore } from "effector";
import { InventoryOverviewItem } from "@gql/retail-inventory-overview-types";

interface State {
  items: InventoryOverviewItem[];
  total: number;
  canQueryRetailAd: boolean;
}

export const initialState: State = {
  items: [],
  total: 0,
  canQueryRetailAd: false,
};

const $itemsStore = createStore<State>(initialState);

export default $itemsStore;
