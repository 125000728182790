import { useEffect } from "react";
import { Card, Select, Input, Space, Button, Form } from "antd";
import { SearchOutlined, CloseCircleFilled } from "@ant-design/icons";
import adjustDateRange from "@utils/adjustDateRange";
import { DataQaSelectorEnum } from "@enums/dataQaSelector.enum";
import DateRangePicker from "../DateRangeControlled";
import Label from "../Label";
import cn from "./styles.less";

const { Option } = Select;
const { useForm } = Form;

export type State = {
  dateRange: readonly [Date | null, Date | null];
  retailStatus: string | undefined;
  retailCountry: string | undefined;
  searchText: string | undefined;
};
export enum Placeholders {
  dateFrom = "From",
  dateTo = "To",
  retailStatus = "Select Retail Status",
  retailCountry = "Select Country",
  searchText = "VIN or StockNumber",
}
export type CountryType = { label: string; value: string };

const initialState: State = {
  dateRange: [null, null],
  retailStatus: undefined,
  retailCountry: undefined,
  searchText: undefined,
};

interface Props {
  initialValues?: State;
  handleSubmit?: (state: State) => void;
  countries?: CountryType[];
  retailStatuses?: RetailStatus[];
}

function Filters({
  initialValues = initialState,
  handleSubmit = () => {},
  countries = [],
  retailStatuses = [],
}: Props) {
  const [form] = useForm();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  return (
    <Card title="Search for Stock" className={cn.container}>
      <Form
        name="inventory-overview-search"
        form={form}
        initialValues={initialValues}
        onFinish={(values: State) => handleSubmit(values)}
      >
        <Space align="end" wrap={true}>
          <Label text="Date">
            <Form.Item
              name="dateRange"
              noStyle={true}
              normalize={adjustDateRange}
            >
              <DateRangePicker
                controllerProps={{
                  size: "large",
                  "data-qa-selector": DataQaSelectorEnum.fromToDateWrapper,
                  placeholder: [Placeholders.dateFrom, Placeholders.dateTo],
                  allowClear: true,
                  allowEmpty: [true, true],
                  className: cn.minWidth,
                }}
              />
            </Form.Item>
          </Label>

          <Label text="Retail Status:">
            <Form.Item name="retailStatus" noStyle={true}>
              <Select
                className={cn.minWidth}
                showSearch={true}
                size="large"
                placeholder={Placeholders.retailStatus}
                allowClear={true}
                clearIcon={
                  <CloseCircleFilled
                    data-qa-selector={DataQaSelectorEnum.clearButton}
                  />
                }
                data-qa-selector-name={DataQaSelectorEnum.retailAdState}
              >
                {retailStatuses.map(({ id, label }) => (
                  <Option value={id} key={id} data-qa-selector-option-label>
                    {label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Label>
          <Label text="VIN or StockNumber:">
            <Form.Item name="searchText" noStyle={true}>
              <Input
                size="large"
                placeholder={Placeholders.searchText}
                className={cn.minWidth}
                name="searchText"
              />
            </Form.Item>
          </Label>
          <Label text="Country:">
            <Form.Item name="retailCountry" noStyle={true}>
              <Select
                className={cn.minWidth}
                size="large"
                showSearch={true}
                placeholder={Placeholders.retailCountry}
                allowClear={true}
                data-qa-selector={DataQaSelectorEnum.select}
                data-qa-selector-name={DataQaSelectorEnum.country}
                filterOption={(input, option): boolean => {
                  const string: string | number = option?.value || "";
                  return string
                    .toString()
                    .toLowerCase()
                    ?.includes(input.toLowerCase());
                }}
              >
                {countries.map(({ value, label }) => (
                  <Option
                    value={value}
                    key={value}
                    data-qa-selector={DataQaSelectorEnum.option}
                  >
                    {label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Label>
          <Button
            icon={<SearchOutlined />}
            size="large"
            type="primary"
            htmlType="submit"
            data-qa-selector={DataQaSelectorEnum.searchButton}
          />
        </Space>
      </Form>
    </Card>
  );
}

export default Filters;
