import dayjs from "dayjs";

export default function formatDate(isoDateTime?: string): string {
  if (!isoDateTime) {
    return "";
  }
  const date = dayjs(isoDateTime);
  if (!date.isValid()) {
    return "";
  }
  return date.format("DD/MM/YYYY HH:mm");
}
