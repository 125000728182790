import { CURRENCY_INFO_BY_CODE } from "@constants/currency";
import { unitsFromMinorToMajor } from "./unitConversion";

export default function formatPrice({
  amountMinorUnits,
  currencyCode,
}: Price): string {
  const amount = unitsFromMinorToMajor(amountMinorUnits);
  const [, currencyInfo] =
    Object.entries(CURRENCY_INFO_BY_CODE).find(
      ([key]) => key === currencyCode
    ) ?? [];
  if (!currencyInfo) {
    return String(amount);
  }
  const currencyFormat = new Intl.NumberFormat(currencyInfo.formatLocale, {
    currency: currencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  return `${currencyFormat.format(amount)} ${currencyInfo.currencySymbol}`;
}
