export enum DataQaSelectorEnum {
  cellFirstImportOn = "cell-firstImportOn",
  cellRetailAdState = "cell-retailAdState",
  cellTotalOrders = "cell-totalOrders",
  cellCountryCode = "cell-countryCode",
  cellVin = "cell-vin",
  cellStockNumber = "cell-stockNumber",
  fromToDateWrapper = "from-to-date-wrapper",
  retailAdState = "retailAdState",
  country = "country",
  select = "select",
  option = "option",
  searchButton = "searchButton",
  switchBtn = "switch-btn",
  clearButton = "clearButton",
}
