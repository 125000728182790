import { Space, Typography, Row, Col, Pagination } from "antd";
import { useStore } from "effector-react";
import Filters, {
  CountryType,
  State as FiltersState,
} from "@components/Filters";
import DataTable from "@components/DataTable";
import {
  setPage,
  setFilters,
  setPageSize,
} from "@models/inventoryOverview/events";
import { $inventoryOverviewData } from "@models/inventoryOverview/projections";
import Gate from "@models/inventoryOverview/gate";
import useQueryParams from "@hooks/useQueryParams";
import { mapToFilterParams } from "@models/inventoryOverview/effects";
import { $filtersDictionaries } from "@models/dictionaries/projections";
import cn from "./styles.less";

const handleFiltersSubmit = function (state: FiltersState): void {
  setFilters({
    dateFrom: state.dateRange[0]?.toISOString() ?? null,
    dateTo: state.dateRange[1]?.toISOString() ?? null,
    retailStatus: state.retailStatus ?? null,
    retailCountry: state.retailCountry ?? null,
    searchText: state.searchText ?? null,
  });
};

export type FilterType = {
  auto1Statuses: Auto1Status[];
  retailStatuses: RetailStatus[];
  countries: CountryType[];
};

function ListPage() {
  const {
    items,
    page,
    pageSize,
    totalItems,
    filters,
    isLoading,
    canQueryRetailAd,
  } = useStore($inventoryOverviewData);
  const { countries, retailStatuses, auto1Statuses } =
    useStore<FilterType>($filtersDictionaries);
  const queryParams = useQueryParams();
  return (
    <Space direction="vertical" size="large" className={cn.container}>
      <Gate {...mapToFilterParams(queryParams ?? {})} />
      <Row align="middle">
        <Col xs={12}>
          <Typography.Title>INVENTORY OVERVIEW</Typography.Title>
        </Col>
      </Row>
      <Filters
        initialValues={filters}
        handleSubmit={handleFiltersSubmit}
        countries={countries}
        retailStatuses={retailStatuses}
      />
      <Row align="middle" justify="space-between">
        <Col>
          <Typography.Title level={4}>
            {totalItems} total results
          </Typography.Title>
        </Col>
        <Col>
          <Pagination
            current={page}
            total={totalItems}
            pageSize={pageSize}
            onChange={(page) => setPage(page)}
            onShowSizeChange={(_, pageSize) => setPageSize(pageSize)}
            showSizeChanger={true}
          />
        </Col>
      </Row>
      <Row>
        <Col flex={1}>
          <DataTable
            data={items}
            loading={isLoading}
            canQueryRetailAd={canQueryRetailAd}
            auto1Statuses={auto1Statuses}
            retailStatuses={retailStatuses}
          />
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Pagination
            current={page}
            total={totalItems}
            pageSize={pageSize}
            onChange={(page) => setPage(page)}
            onShowSizeChange={(_, pageSize) => setPageSize(pageSize)}
          />
        </Col>
      </Row>
    </Space>
  );
}

export default ListPage;
