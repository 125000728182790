export enum METHODS {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
  DELETE = "DELETE",
}

export interface Options {
  signal?: AbortSignal;
  authToken?: string;
}

const callApi = async <R extends unknown>(
  method: METHODS,
  url: string,
  body?: any,
  options?: Options
): Promise<R> => {
  const headers = {
    "access-domain": "ad.management",
    accept: "application/json, text/plain, */*",
    "content-type": "application/json;charset=UTF-8",
    authorization: `Bearer ${options?.authToken}`,
  };

  const response = await fetch(url, {
    method,
    headers,
    body: JSON.stringify(body),
  });

  return response.json();
};

export default callApi;
