import localizedCountries from "localized-countries/data/en_GB.json";
import { combine } from "effector";
import $auto1StatusesStore from "./auto1Statuses.store";
import $countriesStore from "./countries.store";
import $retailStatusesStore from "./retailStatuses.store";
import { propOr, sortBy, prop, pipe, map } from "ramda";
import { CountryType } from "@components/Filters";
import { FilterType } from "@pages/ListPage";

export const $filtersDictionaries = combine<FilterType>({
  auto1Statuses: $auto1StatusesStore,
  retailStatuses: $retailStatusesStore,
  countries: $countriesStore.map(
    pipe(
      map(
        (code: string): CountryType => ({
          label: propOr(code, code, localizedCountries),
          value: code,
        })
      ),
      sortBy(prop("label"))
    )
  ),
});
