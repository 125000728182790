import { useEffect, useState } from "react";
import { parse, ParsedQuery } from "query-string";

export default function useQueryParams() {
  const [state, setState] = useState<ParsedQuery>(() =>
    parse(location.search.slice(1))
  );
  useEffect(() => {
    const handler = () => {
      const queryString = location.search.slice(1);
      setState(parse(queryString));
    };
    window.addEventListener("popstate", handler);
    return () => window.removeEventListener("popstate", handler);
  });

  return state;
}
