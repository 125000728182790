import { forward, merge } from "effector";
import { errorMessage } from "./message/events";
import {
  errors as inventoryOverviewErrors,
  init as initData,
} from "./inventoryOverview/init";
import {
  errors as dictionariesErrors,
  init as initDictionaries,
} from "./dictionaries/init";
import "./message/init";
import { $onInit } from "./auth/init";

forward({
  from: merge([inventoryOverviewErrors, dictionariesErrors]),
  to: errorMessage,
});

forward({
  from: $onInit,
  to: initDictionaries,
});

forward({
  from: $onInit,
  to: initData,
});
