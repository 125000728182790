import { createEffect } from "effector";
import { ParsedQuery, stringify } from "query-string";
import serviceApi from "@utils/serviceApi";
import { FilterParams, InputParams } from "./types";
import { DEFAULT_PAGE, DEFAULT_RETAIL_STATUS, PAGE_SIZE } from "./constants";

export const getInventoryOverviewsFX = createEffect(
  async ({
    inputParams,
    authToken,
  }: {
    inputParams: InputParams;
    authToken: AuthToken;
  }) =>
    (
      await serviceApi.inventoryOverview(
        {
          input: inputParams,
        },
        {
          authToken,
        }
      )
    ).inventoryOverviewPage
);

export const applyFilterParamsFX = createEffect(
  async (filterParams: FilterParams) => {
    const params = Object.entries(filterParams).reduce((acc, [key, value]) => {
      if (value !== null && value !== "") {
        acc[key] = value;
      }
      return acc;
    }, {} as Record<string, string | number>);
    const queryString = stringify(params);
    if (queryString !== location.search.slice(1)) {
      history.pushState(
        {
          userEvent: true,
        },
        "",
        `${location.pathname}?${queryString}`
      );
    }
  }
);

// Map query params to filter params
export function mapToFilterParams<T extends ParsedQuery>(
  params: T
): FilterParams {
  return {
    dateFrom:
      (Array.isArray(params["dateFrom"])
        ? params["dateFrom"].pop()
        : params["dateFrom"]) ?? null,
    dateTo:
      (Array.isArray(params["dateTo"])
        ? params["dateTo"].pop()
        : params["dateTo"]) ?? null,
    retailStatus:
      (Array.isArray(params["retailStatus"])
        ? params["retailStatus"].pop()
        : params["retailStatus"]) ?? DEFAULT_RETAIL_STATUS,
    retailCountry:
      (Array.isArray(params["retailCountry"])
        ? params["retailCountry"].pop()
        : params["retailCountry"]) ?? null,
    searchText:
      (Array.isArray(params["searchText"])
        ? params["searchText"].pop()
        : params["searchText"]) ?? null,
    page: Number(
      (Array.isArray(params["page"]) ? params["page"].pop() : params["page"]) ??
        DEFAULT_PAGE
    ),
    pageSize: Number(
      (Array.isArray(params["pageSize"])
        ? params["pageSize"].pop()
        : params["pageSize"]) ?? PAGE_SIZE
    ),
  };
}
