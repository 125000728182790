import { createStore } from "effector";
import { equals } from "ramda";
import { FilterParams } from "./types";
import { DEFAULT_PAGE, PAGE_SIZE, DEFAULT_RETAIL_STATUS } from "./constants";

export const initalState: FilterParams = {
  page: DEFAULT_PAGE,
  pageSize: PAGE_SIZE,
  dateFrom: null,
  dateTo: null,
  retailStatus: DEFAULT_RETAIL_STATUS,
  retailCountry: null,
  searchText: null,
};

const $filterParamsStore = createStore<FilterParams>(initalState, {
  updateFilter: (update, current) => !equals(update, current),
});

export default $filterParamsStore;
